import { render, staticRenderFns } from "./ClientCertificate.vue?vue&type=template&id=44c852d2&"
import script from "./ClientCertificate.vue?vue&type=script&lang=js&"
export * from "./ClientCertificate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports